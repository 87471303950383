.slide {
    &-calendar {

        .slide-body {
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            position: relative;
        }

        .slide-content {
            position: absolute;
            left: 50%;
            margin: 39vh 0 0 6vh;
            width: 70vh;
        }
        .dates-list {
            counter-reset: calendar-counter;
            // background: #ff00ff20;
            &__item {
                height: 9vh;
                counter-increment: calendar-counter;
                display: flex;
                align-items: center;
                font-size: 2.8vh;
                line-height: 1.2em;
                // white-space: nowrap;
                hyphens: none;
                
                &.today {
                    background: #3f3f3f;
                    color: #fff;
                }
                &::before {
                    content: "0" counter(calendar-counter);
                    width: 9vh;
                    height: 9vh;
                    display: flex;
                    flex-shrink: 0;
                    font-size: 5vh;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
}