.slide {
    $root: &;
    &-image {
        .slide-content {            

            img {
                max-width: 100%;
                max-height: 100%;
                bottom: 0;
                left: 0;
                margin: auto;
                overflow: auto;
                position: fixed;
                right: 0;
                top: 0;
                -o-object-fit: contain;
                object-fit: contain;
            }
        }
    }
}