.slide-video {
  .slide-body {
    height: 100%;
  }

  .slide-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    video {
      width: 100%;
    }
  }
}
