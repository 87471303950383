body {
    // background: #ffc;
  }
  
  #root {
    height: 100vh; 
    display: flex;
    align-items: center;
    justify-content: center;       
  }
  
  swiper {
    
    &-container {
      width: 100%;
      height: 100%;
      margin-left: auto;
      margin-right: auto;
      display: flex;
    }
  
    &-slide {
      width: 100%;
      height: 100%;
  
      background: #fff;
  
      &:nth-child(odd) {
        // background: #fcf;
      }    
    }
  }

    /***************************************************************************************/
    /* ANCHOR COLUMN SYSTEM */
$gap: 0;

  .row {

    display: flex;
    flex-wrap: wrap;
    gap: $gap;

    margin: 0 auto;

    &--full {
        max-width: 100%;
        min-height: 100%;
        padding: 0;
    }

  }



    /***************************************************************************************/
    /* ANCHOR .column */


    .column {
      $self: &;
      min-height: 10rem;
     
      
      &--25 {
          flex-grow: 1;
          flex-shrink: 0;

          .row--full & {
              min-width: $column_width-full;
          }
      }
      &--50 {
          flex-grow: 1;
          flex-shrink: 0;
          flex-basis: calc(50% - #{$gap}) !important;
          min-width: minmax($column_width-default*2, 100%);

      }
      &--75 {
          flex-grow: 1;
          flex-shrink: 1;            
          flex-basis: calc(75% - #{$gap}) !important;
          min-width: 75%;
      }
      &--100 {
          flex-grow: 1;
          flex-shrink: 1;            
          flex-basis: 100%;
          min-width: 100%;
      }
  }