.slide {
    &-welcome {
        .slide-body {
            width: 100*1.777778vh;
            height: 100vh;
            display: flex;
            flex-direction: column;
            .slide-header {
                height: 30vh;
                padding-left: 18vh;
                display: flex;
                align-content: end;
                h1 {
                    align-self: flex-end;
                    color: #fff;
                    font-size: 5vh;
                    margin-bottom: 3vh;
                }
            }

            .slide-content {
                height: 70vh;
                padding: 6vh 18vh 0 18vh;

                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(20vh, 1fr));
                gap: 10vh;
              
                color: #fff;

                .visitor {
                    width: 30vh;
                    display: flex;
                    flex-direction: column;

                    &:nth-child(1):only-child {
                        flex-direction: row;
                        max-width: 100vh;
                        width: auto;
                        gap: 8vh;
                        align-items: flex-start;
                    }

                    &-logo {
                        background-color: #fff;
                        padding: 2vh 4vh;
                        margin-bottom: 2vh;
                        width: 30vh;
                        flex-shrink: 0;
                        img {
                            
                        }
                    }

                    &-text {
                        font-size: 2.8vh;
                        line-height: 1.2em;
                    }
                }
            }
        }
    }
}