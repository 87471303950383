
* {
  font-family: 'Barlow Regular';
  font-weight: normal;
}


h1 {
  font-family: 'Barlow SemiBold';
  letter-spacing: 0.05vh;
  font-size: 2.8em;
  line-height: 1.1em;
}

p {
  font-size: 1.2em;
  line-height: 1.2em;
  margin: 0 0 2rem;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  -moz-hyphens: auto;
   -o-hyphens: auto;
   -webkit-hyphens: auto;
   -ms-hyphens: auto;
   hyphens: auto;
}

a {
  color: #fff;
  text-decoration: none;
}
