.slide {
  &-logos {

    background: #ededed;

    .slide-header {
      height: 20vh;
      padding: 5rem;
    }

    .slide-content {
      height: 80vh;
      padding: 0 8rem 5rem;
      display: grid;
      gap: 1rem;

      .logo-list {

        &__item {
          display: flex;
          justify-content: center;
          align-items: center;
          background: #fff;
          padding: 30px;
          // max-height: 13vh;

          img {
            width: auto;
            height: auto;
            max-height: 100%;
            max-width: 100%;
          }
        }
      }
    }
  }
}
