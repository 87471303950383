/* #### Generated By: http://www.cufon../fonts.com #### */

@font-face {
    font-family: 'Barlow Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Barlow Regular'), url('../../fonts/barlow/Barlow-Regular.woff') format('woff');
    }

    @font-face {
    font-family: 'Barlow Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Barlow Italic'), url('../../fonts/barlow/Barlow-Italic.woff') format('woff');
    }

    @font-face {
    font-family: 'Barlow Thin';
    font-style: normal;
    font-weight: normal;
    src: local('Barlow Thin'), url('../../fonts/barlow/Barlow-Thin.woff') format('woff');
    }

    @font-face {
    font-family: 'Barlow Thin Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Barlow Thin Italic'), url('../../fonts/barlow/Barlow-ThinItalic.woff') format('woff');
    }

    @font-face {
    font-family: 'Barlow ExtraLight';
    font-style: normal;
    font-weight: normal;
    src: local('Barlow ExtraLight'), url('../../fonts/barlow/Barlow-ExtraLight.woff') format('woff');
    }

    @font-face {
    font-family: 'Barlow ExtraLight Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Barlow ExtraLight Italic'), url('../../fonts/barlow/Barlow-ExtraLightItalic.woff') format('woff');
    }

    @font-face {
    font-family: 'Barlow Light';
    font-style: normal;
    font-weight: normal;
    src: local('Barlow Light'), url('../../fonts/barlow/Barlow-Light.woff') format('woff');
    }

    @font-face {
    font-family: 'Barlow Light Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Barlow Light Italic'), url('../../fonts/barlow/Barlow-LightItalic.woff') format('woff');
    }

    @font-face {
    font-family: 'Barlow Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Barlow Medium'), url('../../fonts/barlow/Barlow-Medium.woff') format('woff');
    }

    @font-face {
    font-family: 'Barlow Medium Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Barlow Medium Italic'), url('../../fonts/barlow/Barlow-MediumItalic.woff') format('woff');
    }

    @font-face {
    font-family: 'Barlow SemiBold';
    font-style: normal;
    font-weight: normal;
    src: local('Barlow SemiBold'), url('../../fonts/barlow/Barlow-SemiBold.woff') format('woff');
    }

    @font-face {
    font-family: 'Barlow SemiBold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Barlow SemiBold Italic'), url('../../fonts/barlow/Barlow-SemiBoldItalic.woff') format('woff');
    }


    @font-face {
    font-family: 'Barlow Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Barlow Bold'), url('../../fonts/barlow/Barlow-Bold.woff') format('woff');
    }

    @font-face {
    font-family: 'Barlow Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Barlow Bold Italic'), url('../../fonts/barlow/Barlow-BoldItalic.woff') format('woff');
    }

    @font-face {
    font-family: 'Barlow ExtraBold';
    font-style: normal;
    font-weight: normal;
    src: local('Barlow ExtraBold'), url('../../fonts/barlow/Barlow-ExtraBold.woff') format('woff');
    }

    @font-face {
    font-family: 'Barlow ExtraBold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Barlow ExtraBold Italic'), url('../../fonts/barlow/Barlow-ExtraBoldItalic.woff') format('woff');
    }


    @font-face {
    font-family: 'Barlow Black';
    font-style: normal;
    font-weight: normal;
    src: local('Barlow Black'), url('../../fonts/barlow/Barlow-Black.woff') format('woff');
    }

    @font-face {
    font-family: 'Barlow Black Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Barlow Black Italic'), url('../../fonts/barlow/Barlow-BlackItalic.woff') format('woff');
    }
